<template>
  <div>
    <topmenu :comname="'forum'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>联系方式</span>
      </div>
      <!-- <div class="widht200 fl">
        <contact></contact>
      </div> -->
      <div class="widht1200 fr">
        <div class="listinfor" v-loading="loading">
          <ul class="whiteLump">
            <li v-for="(item,index) in 4" :key="index"></li>
          </ul>
          <ul class="contal">
            
            <li>
              <!-- <i class="czqy"></i> -->
              <img src="@/assets/images/contal_1.png" alt="" class="img1">
              <span class="font16 style_color_main">- 参展热线 -</span>
              <p class="font16 style_color_ash">冯卫章</p>
              <p class="font16" style="font-weight:700;color:black"> 199-1234-8021</p>
              <img src="@/assets/images/exhibitionHotline.png" alt="" class="img2">
              </li>
            
            <li>
              <!-- <i class="czqy"></i> -->
              <img src="@/assets/images/contal_2.png" alt="" class="img1">
              <span class="font16 style_color_main">- 观展预约 -</span>
              <p class="font16 style_color_ash">闫文静 </p>
              <p class="font16" style="font-weight:700;color:black"> 199-1234-8097</p>
              <img src="@/assets/images/exhibitionBooking.png" alt="" class="img2">

            </li>
            <li>
              <!-- <i class="czqy"></i> -->
              <img src="@/assets/images/contal_3.png" alt="" class="img1">
              <span class="font16 style_color_main">- 媒体合作 -</span>
              <p class="font16 style_color_ash">张硕 </p>
              <p class="font16" style="font-weight:700;color:black"> 177-3255-9951</p>
              <img src="@/assets/images/media cooperation.png" alt="" class="img2">
            </li>
            <li>
              <!-- <i class="czqy"></i> -->
              <img src="@/assets/images/contal_4.png" alt="" class="img1">
              <span class="font16 style_color_main">- 会议地址 -</span>
              <p class="font14 style_color_ash">无锡市宜兴市丁蜀镇<br>公园路206号紫砂宾馆</p>
              <img src="@/assets/images/conferenceAddress.png" alt="" class="img2">

            </li>
            <img src="@/assets/images/contactUsTop.png" alt="" class="top">
          </ul>
          
        </div>
        <!-- <nocont></nocont> -->
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
  name: "forum",
  data() {
    return {
      id: "",
      list: [],
      isshowlist:true,
      loading:true,
      currentPage: 1, //当前页
      pagesize: 6, //每页显示条数
      totalPage: 0 //总页数
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getinformation();
  },
  watch: {
    $route: function(to, from) {
      this.id = this.$route.query.id;
    },
    //监听翻页
    currentPage: function(newVal, oldVal) {
      this.loading = true;
      this.getinformation();
    }
  },
   filters: {
    formatDate: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    }
  },
  methods: {
    handleSizeChange: function(size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
    getinformation() {
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true
      };
      this.$axios
        .post("/api/app/exhibitionForum/getExhibitionForumPageList", data)
        .then(res => {
          this.loading=false;
          this.totalPage=res[1].pages;
          if (res[0].length > 0) {
            let infordata = res[0];
            this.list = infordata;
            console.log(this.list,'asdfsdf');
            this.isshowlist=true
            }else{
            this.isshowlist=false
            }
        });
    }
  }
};
</script>
<style scoped>
.listinfor{
  padding: 40px 0;
}
.whiteLump{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.whiteLump li{
  width: 250px;
  height: 68px;
  border: 1px solid rgba(24,47,179,.15);
  border-radius: 6px;
}
.contal{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
}
.contal li{
  width: 250px;
  height: 770px;
  border: 1px solid rgba(24,47,179,.15);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
}
.contal li p{
  line-height: 20px;
}
.contal li span{
  margin: 30px auto 20px;
  font-weight: 700;
}
.contal li .img1{
  /* width: 52px; */
  height: 52px;
  margin: 0 auto;
}
.contal li .img2{
  max-width: 185px;
  margin: 76px auto 14px;
}
.contal li:nth-child(1){
  background: linear-gradient(-45deg,rgba(22,144,255,.15) 1%, rgba(232,239,255,.15) 100%);
}
.contal li:nth-child(2){
  background: linear-gradient(-45deg,rgba(62,201,105,.15) 1%, rgba(232,239,255,.15) 100%);
}
.contal li:nth-child(3){
  background: linear-gradient(-45deg,rgba(252,198,74,.15) 1%, rgba(232,239,255,.15) 100%);
}
.contal li:nth-child(4){
  background: linear-gradient(-45deg,rgba(255,69,3,.15) 1%, rgba(232,239,255,.15) 100%);
  position: relative;
}
.contal li:nth-child(4)::before{
  position: absolute;
  right: 0;
  top: 30px;
  content: '+';
  color: #182FB3;
  font-size: 74px;
  width: 39px;
  font-weight: 700;
}
.top{
  position: absolute;
  left: 2px;
  top: 38px;
}
</style>